<template>
  <div>
    <h1>{{ content.title }}</h1>
    <Toc v-if="content.generateToc" :items="tocItems"></Toc>
    <div class="cms-content" v-html="content.content"></div>
  </div>
</template>

<script>
import Toc from "../../components/base/Toc"
import { gql } from "apollo-boost"
import StaticContent from "@/mixins/StaticContent.vue"

export default {
  name: "General",
  mixins: [StaticContent],
  components: {
    Toc
  },
  apollo: {
    content: {
      query: gql`
        query getAbout {
          about {
            data {
              attributes {
                title
                content
                generateToc: toc
              }
            }
          }
        }
      `,
      update: (data) => data.about.data.attributes
    }
  }
}
</script>
